.App {
	min-height: 100vh; /* App takes up the full viewport height */
	display: flex;
	flex-direction: column; /* Stacks children vertically */
	text-align: center;
	margin: 0;
	padding: 0;
}

html {
	background-color: white;
}

body {
	margin: 0;
	padding: 0;
}

.App-footer {
	color: black;
	margin-top: auto;
	text-align: center;
	padding: 15px;
}

.payment-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh; 
  }

.FormGroup {
	margin: auto;
	padding: 0;
	border-style: none;
	background-color: #7795f8;
	will-change: opacity, transform;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
		inset 0 1px 0 #829fff;
	border-radius: 4px;
	width: 55%;
}

.FormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
	border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 50%;
	padding: 11px 15px 11px 0;
}

@media only screen and (max-width: 600px) {
    .StripeElement {
        width: 100%; 
    }
}

button {
	display: block;
	font-size: 16px;
	width: calc(15%);
	height: 40px;
	margin: auto;
	margin-top: 10px;
	background-color: #7795f8;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
		inset 0 1px 0 #7795f8;
	border-radius: 4px;
	color: whitesmoke;
	font-weight: 600;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
	border: none;
}

button:hover {
	background-color: green;
}

button:active {
	background-color: green;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
		inset 0 1px 0 green;
	transform: scale(0.99);
}

@media only screen and (max-width: 600px) {
    button {
        width: 90%;
    }
}
